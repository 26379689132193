import React, { useState, useEffect } from "react";
import Loginimg from "../../assets/images/login-img.png";
import logo from "../../assets/images/login-logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { ToastMessgae } from "../utils/toast";
import BASE_URL from "../config/baseUrl";
export default function Login({onSuccess}) {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token != null) {
      navigate("/");
    }
  });


  let [formval, setFormval] = useState({ phone: "", pin: "" });
  let ChangeHandler = (e) => {
    setFormval((currVal) => {
      return { ...currVal, [e.target.name]: e.target.value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formval);
    // console.log(formval);
    if (!formval.phone || !formval.pin) {
      return alert("all fileds is required");
    }
    try {
      const response = await axios(
        `${BASE_URL}/api/user/login`,
        {
          method: "POST",
          data: formval,
        }
      );
      console.log(response.data);
      // console.log(response.data.token);
      if (response.data.token) {
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.user)
        );
        localStorage.setItem(
          "role",
          JSON.stringify(response?.data?.role)
        );
        localStorage.setItem(
          "RolePermissionData",
          JSON.stringify(response?.data?.RolePermissionData)
        );
        // console.log(response?.data?.user?.user_type?._id);
        
        onSuccess(response?.data?.user?.user_type?._id)
        navigate("/");
      } else {
        alert(response?.data?.message || `Something Went Wrong From Server Side`);
      }
    } catch (error) {
      console.log(error);
      
      alert("Something Went Wrong From Server Side")
      console.log("Error :", error?.response?.data);
    }
  };

  return (
    <>
      <section className="main-login">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-6 b-center bg-size d-none d-lg-block d-xl-block"
              style={{
                backgroundImage: `url(${Loginimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                display: "block"
              }}
            >
              <div className="login-lft">
                <div className="login-lft-innr">
                  <h3>Welcome</h3>
                  <img src={logo} alt="" />
                  <p className="text-white">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio
                    vel, laborum omnis atque quisquam ducimus, eius expedita alias!
                    Repellat alias sint at dignissimos adipisci quis vitae quod culpa
                    labore voluptatum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 p-0">
              <div className="login-card">
                <form action="" onSubmit={handleSubmit} className="login-form">
                  <h4>
                    <span>Login</span>
                  </h4>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="" className="form-label">
                      User Name/Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      placeholder="Enter User Name/Mobile Number"
                      onChange={ChangeHandler}
                      value={formval.phone}
                    />
                    <span className="fas fa-user" />
                  </div>
                  <div className="mb-5 frm-bx">
                    <label htmlFor="" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={ChangeHandler}
                      name="pin"
                      value={formval.pin}
                    />
                    <span className="fas fa-lock" />
                  </div>
                  <div className="mb-4 mt-5 frm-bx">
                    <button className="frm-btn w-100" type="submit">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import BASE_URL from "../config/baseUrl";
import { Link } from "react-router-dom";
// import Ras from "../../assets/images/ras.png"
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
import Swal from "sweetalert2";

export default function LiveVideoCourse_list() {
  let [data, setData] = useState([]);
  // let [categoryData, setCategoryData] = useState([]);
  let [searchCourseName, setSearchCourseName] = useState("");
  let [updateSequence, setUpdateSequence] = useState();
  // let [searchCategory, setSearchCategory] = useState("");
  // let [searchPrice, setSearchPrice] = useState("");
  const [pageNumber, setPageNumber] = useState(1)
  const [showPagination, setShowPagination] = useState(true);
  let [videoCourseCount, setvideoCourseCount] = useState([]);
  let [showIndex, setShowIndex] = useState(false);

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(`${BASE_URL}/api/livevideocourse/allcourses?p=${pageNumber}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response?.data)
      // setData(response?.data?.data);
      if(!(response?.data?.status) && response.status === 200){
        setPageNumber(pageNumber-1)        
        return alert("Page Data Not Found ")
      }
      setData(response?.data?.data);
      setvideoCourseCount(response?.data?.data?.userCount);
    };
    apicall();
  }, [pageNumber]);

  const handleStatus = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/livevideocourse/activevideo/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // const result = await res.json();
      // console.log(response.data)
      // setVideoCourseData((currtable) => {
      //     currtable.map((arr) => {
      //         if (arr._id === response.data.data._id) {
      //             arr.isActive = (response.data.data.isActive)
      //         }
      //     });
      //     return [...currtable]
      // })
    };
    apiCall();
  };

  const handleUpdate = async (e) => {
    // console.log("working")
    if (updateSequence === "") {
      alert("empty is not allowed");
      return;
    }
    let response = await axios(
      `${BASE_URL}/api/livevideocourse/update_sequence/${e}?p=${pageNumber}`,
      {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: { sequence: updateSequence },
      }
    );
    // console.log(response)
    setData(response?.data?.data);
    // setCategoryData([...response?.data?.data]);
  };

  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/livevideocourse/deletecourse/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let deletedUser = response?.data?.data;
      setData(data.filter((arr) => arr?._id !== deletedUser?._id));
      setvideoCourseCount((curr)=>curr-1)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Live Video Course has been deleted.",
          icon: "success",
        });
      }
    });
  };

  // http://localhost:8080/api/livevideocourse/search?category=Programmer

  const searchHandle = async (e) => { 
    e.preventDefault();
    setShowIndex(true) 	
    setShowPagination(false)
    if(searchCourseName === ""){
      setShowPagination(true)
    }
    let response = await axios(
      `${BASE_URL}/api/livevideocourse/search?title=${searchCourseName}`,
      // `${BASE_URL}/api/videocourse/search?title=${searchCourseName}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    console.log(response.data)
    if (!response?.data?.status) {
      alert(response.data.message);
      return
    } else {
      setData(response?.data?.data?.data);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Live Video Course List
              </h4>
              <Link to="/live_video_course_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Live Video Course
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/live_video_course_list">
                      Live Video Course Management
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Live Video Course List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Live Video Course List ({videoCourseCount})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        {/* <div className="col-lg3"> */}
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                          />
                        </div> */}
                        <div className="col-lg-6"><input 
                        type="text" 
                        className="form-control" 
                        placeholder="Course Name" 
                        onChange={(e)=>setSearchCourseName(e.target.value)}
                        /></div>
                        {/* <div className="col-lg-3"><input type="text" className="form-control" onChange={(e)=>setSearchCategory(e.target.value)} placeholder="Category Name"/></div> */}
                        {/* <div className="col-lg-3"><input type="text" className="form-control" placeholder="Price"/></div> */}
                          {/* <div className="col-lg-3"><button type="button" className="blu-btn w-100">Search</button></div> */}
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>COURSE NAME</th>
                      <th>CATEGORY</th>
                      <th>SEQUENCE</th>
                      <th>PRICE</th>
                      <th>OFFER PRICE</th>
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((arr, i) => (
                      <tr key={arr._id}>
                       <th>{showIndex ?++i:++i + 10 * (pageNumber - 1) }</th>
                        <th>
                          <img
                            src={`${BASE_URL}/${arr?.images[0]?.image}`}
                            className="img-40"
                            alt=""
                          />
                        </th>
                        <td>{arr?.title}</td>
                        <td>{arr?.videoCategory?.category || arr?.liveVideoCategory?.category}</td>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              name="sequence"
                              defaultValue={arr?.sequence}
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>₹{arr?.price}</td>
                        <td>₹{arr?.offerPrice}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.isActive}
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            to={`/live_video_course_details/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={view} alt="" />
                          </Link>
                          <Link
                            to={`/live_video_course_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            onClick={() => handleDelete(arr._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <Link className="page-link" to="#" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link active" to="#">
                        1
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        2
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        3
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#" aria-label="Next">
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div> */}
               { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

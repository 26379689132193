import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/head-logo.png";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import RolePermissionContext from "../../contextApi/rolePermissionContext";
 
export default function Sidebar() {
  const { userPermission,setUserPermission} = useContext(RolePermissionContext);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user?.user_type?._id);
  
  const [toggles, setToggles] = useState({
    toggle: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
    toggle5: false,
    toggle6: false,
    toggle7: false,
  });

let [permissionCategory,setPermissionCategory]=useState([])
// let [userPermission,setUserPermission]=useState([])

  const toggleClass = () => {
    document.body.classList.toggle("resize-menu");
  };

  const handleToggle = (key) => {
    setToggles((prevState) => ({
      [key]: !prevState[key],
    }));
  };
  const offToggle = (key) => {
    setToggles({
      toggle: false,
      toggle2: false,
      toggle3: false,
      toggle4: false,
      toggle5: false,
      toggle6: false,
      toggle7: false,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (document.body.classList.contains("resize-menu")) {
        document.body.classList.remove("resize-menu");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect( ()=>{
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/permissioncategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      // console.log(result,"result");
      setPermissionCategory(result?.data);
    };
    apiCall();
    // const rolepermission = async () => {
    //   let response = await fetch(`${BASE_URL}/api/permissioncategory/alldata`, {
    //     method: "GET",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   });
    //   const result = await response.json();
    //   console.log(result,"result");
    //   setPermissionCategory(result?.data);
    // };
    // rolepermission();
    const rolepermission = async () => {
      let response = await axios(
        `${BASE_URL}/api/rolepermission/show/${user?.user_type?._id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data.data );
      // console.log(response.data.data.permissionCategoryId);
     let filter= response?.data?.data?.permissionCategoryId?.map((arr)=>(arr.category))
    //  console.log(userPermission.includes("video Mangement"));
    //  console.log(userPermission.includes("user Mangement"));
     
      setUserPermission(filter)
      // setrolePermission(response?.data?.data);
      // setFormval(response?.data?.data?.permissionId)
    };
    rolepermission();

  },[])
  return (
    <div className="dash-menu">
      <div className="dashbord-logo">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="" />
        </Link>
        <button className="toggle cross-icon" onClick={toggleClass}>
          <i className="fas fa-chevron-left" />
        </button>
      </div>
      <div className="dash-menu-bar">
        <nav className="nav-menu navbar" id="navbar">
          <ul>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <span className="fas fa-home me-1 icon" />
                Dashboard
              </Link>
            </li>
            {userPermission?.includes("user Mangement") && <li className="nav-item">
              <Link to="/user_list" className="nav-link">
                <span className="fas fa-users me-1 icon" />
                Users
              </Link>
            </li>}

           {userPermission?.includes("role Mangement") && <li
              className={`nav-item has-submenu ${
                toggles.toggle3 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle3")}
              >
                <span className="fas fa-user me-1 icon" />
                Roles / Permission
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle3 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/role_list">
                    Roles
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/permission_list">
                    Permission
                  </Link>
                </li>
              </ul>
            </li>}

           {userPermission?.includes("video Mangement") && <li
              className={`nav-item has-submenu ${
                toggles.toggle ? "new-menu" : ""
              }`}
            >
              <Link className="nav-link" onClick={() => handleToggle("toggle")}>
                <span className="fas fa-video me-1 icon" />
                Video Courses
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/video_course_list">
                    Video Courses List
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/video_course_add">
                    Add Video Courses
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/video_course_category_add"
                  >
                    Add Video Course Category
                  </Link>
                </li>
                <li onClick={offToggle}>
                  <Link
                    className="dropdown-item"
                    to="/video_course_category_list"
                  >
                    Category List
                  </Link>
                </li>
              </ul>
            </li>}
            
            {userPermission?.includes("live video Mangement") && <li
              className={`nav-item has-submenu ${
                toggles.toggle2 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle2")}
              >
                <span className="fas fa-video me-1 icon" />
                Live Video Courses
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle2 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/live_video_course_list">
                    Live Video Courses List
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/live_video_course_add">
                    Add Live Video Courses
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/live_video_course_category_add"
                  >
                    Add Live Video Courses Category
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/live_video_course_category_list"
                  >
                    Live Video Courses Category List
                  </Link>
                </li>
              </ul>
            </li>}

            {userPermission?.includes("test Mangement") &&<li
              className={`nav-item has-submenu ${
                toggles.toggle4 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle4")}
              >
                <span className="fa-solid fa-book me-1 icon" />
                Test
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle4 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/test_category_list">
                    Test Category
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/test_package_list">
                    Test Package
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/test_list">
                    Test List
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/question_list">
                    Question Bank
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/subject_list">
                    Subject
                  </Link>
                </li>
              </ul>
            </li>}
            {userPermission?.includes("ras package Mangement") &&<li
              className={`nav-item has-submenu ${
                toggles.toggle5 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle5")}
              >
                <span className="fa-solid fa-box me-1 icon" />
                Ras Package
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle5 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/Ras_Package_list">
                    Ras Package
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ras_package_test_list">
                    Ras Package Test
                  </Link>
                </li>
              </ul>
            </li>}
            {userPermission?.includes("free video/Pdf Mangement") &&<li
              className={`nav-item has-submenu ${
                toggles.toggle6 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle6")}
              >
                <span className="fa-solid fa-box me-1 icon" />
                free Video/Pdf
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle6 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/free_video_pdf_list">
                    free Video/Pdf List
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/free_video_pdf_subject_list"
                  >
                    free Video/Pdf Subject List
                  </Link>
                </li>
              </ul>
            </li>}
            {userPermission?.includes("banner Mangement") &&<li
              className={`nav-item has-submenu ${
                toggles.toggle7 ? "new-menu" : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={() => handleToggle("toggle7")}
              >
                <span className="fa-solid fa-box me-1 icon" />
                banner
              </Link>
              <ul
                className={`submenu collapse ${toggles.toggle7 ? "show" : ""}`}
              >
                <li>
                  <Link className="dropdown-item" to="/banner_list">
                    banner
                  </Link>
                </li>
                {/* <li>
                  <Link className="dropdown-item" to="/free_video_pdf_subject_list">
                  free Video/Pdf Subject List
                  </Link>
                </li> */}
              </ul>
            </li>}
          </ul>
        </nav>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
// import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
export default function Test_edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [formval, setFormval] = useState({
    title: "",
    description: "",
    totalQuestions: "",
    duration: "",
    marks: "",
    negMarks: "",
    testPackage: "",
    schedule_date: "",
    announcement_date: "",
    subject: "",
    course_type:"",
    syllabuspdf:""
  });;
  let [testPackage, setTestPackage] = useState([]);
  let [subject, setSubject] = useState([]);
  let [subjectCount, setSubjectCount] = useState([]);
  let [row, setRow] = useState([]);
  const [testCategory, settestCategory] = useState([])
  let [pdf, setPdf] = useState("");
  

  let pdfError=(e) => {

  };



  const handelOnChange = (e) => {
 if(e.target.name === "syllabuspdf"){
   let file= e?.target?.files[0];
   if(file){
     const reader = new FileReader();
     reader.onload = () => {
       setPdf(reader?.result);
     };
     reader.readAsDataURL(e?.target?.files[0]);
    return setFormval((currVal) => ({ ...currVal, syllabuspdf: e.target.files[0] }));
      }
    }
    if (e.target.type === "number") {
      if(e.target.value < 0){
        return 
      }
    }
    if(e.target.name === "testCategory"){

      let apicall = async () => {
        let response = await axios(`${BASE_URL}/api/test/test_category_data/${e.target.value}`, {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
      
        setTestPackage(response?.data?.data);
      };
      apicall();
    }
    setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(`${BASE_URL}/api/test/show/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response?.data?.data) 
      setFormval(response?.data?.data?.userData);
      setRow(response?.data?.data?.subjectDetails)
      setSubjectCount(response?.data?.data?.subjectCount)
    };
    apicall();

    let testpackege = async () => {
      let response = await axios(`${BASE_URL}/api/testpackege/allcourses`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setTestPackage(response?.data.data);
    };
    testpackege();

    const subjectapiCall = async () => {
      let response = await axios(`${BASE_URL}/api/subject/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      let filterData = response?.data?.data;
      filterData = response?.data?.data.filter((arr) => arr?.isActive === true);
      setSubject(filterData);
    };
    subjectapiCall();

    let testCategoryApiCall = async () => {
      let response = await axios(`${BASE_URL}/api/testcategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      let filterData = response?.data?.data;
      filterData = response?.data?.data.filter((arr) => arr?.isActive === true);
      settestCategory(filterData);
    };
    testCategoryApiCall();
  }, []);

  

  const addRow = () => {
    setRow([
      ...row,
      { subject: "", totalQuestions: "", marks: "", negMarks: "" },
    ]);
  };
  const removeRow = (index) => {
    const newRows = row.filter((row, i) => i !== index);
    setRow(newRows);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setRow((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, [name]: value } : row
      )
    );
  };
 
  const handelOnSubmit = (e) => {
    e.preventDefault();
    // console.log(formval)
    // console.log(row)
    // return 
    if (
      !formval.title ||
      !formval.description ||
      !formval.duration ||
      !formval.marks ||
      // !formval.negMarks ||
      !formval.totalQuestions
    ) {
      alert("all filed is required");
      return;
    }
    for (let arr of row){
      if (!arr.subject||!arr.marks ||!arr.totalQuestions) {
        alert("subect all  filed is required");
        return
      }
    }
    let formData=new FormData();

    formData.append('syllabuspdf', formval.syllabuspdf);
    formData.append('formval', JSON.stringify(formval));
    formData.append('testSubject', JSON.stringify(row))

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/test/edit/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data:formData,
      });
      // console.log(response.data);
      if (!(response?.data?.status)) {
        alert("Some Error Accoured Form Server Side Check Console");
        console.log(response?.data?.message)
        return;
      }
      navigate("/test_list");
    };
    apiCall();
  };


  const deletetestSubject= async (subjectId)=>{
    // console.log(subjectId)
    let response = await axios(`${BASE_URL}/api/test/test_subject/${subjectId}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    setRow((prv)=>{
      prv=prv.filter((arr)=>arr?._id !== response?.data?.data?._id)
      return prv
    });
  }
  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Edit Test
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Test
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                <div className="row">
                <div className="mb-3 col-lg-4">
                    <label className="form-label">Test Category</label>
                    <select
                      className="form-select"
                      name="testCategory"
                      onChange={handelOnChange}
                      value={formval?.testCategory}
                    >
                      <option>Select Test Category</option>
                      {testCategory?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Test Package</label>
                    <select
                      className="form-select"
                      name="testPackage"
                      onChange={handelOnChange}
                      value={formval?.testPackage?._id}
                    >
                      <option>Select Test Package</option>
                      {testPackage?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Test Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handelOnChange}
                      value={formval?.title}
                    />
                  </div>

                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Schedule Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="schedule_date"
                      onChange={handelOnChange}
                      value={formval?.schedule_date?.split("T")[0]}
                      // min={today}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Announcement Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="announcement_date"
                      onChange={handelOnChange}
                      value={formval?.announcement_date?.split("T")[0]}
                      // min={today}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Total Time (In Mintus)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="duration"
                      onChange={handelOnChange}
                      value={formval?.duration}
                      min={0}
                    />
                  </div>

                  <div className="col-lg-4 mb-3 ">
                    <label htmlFor="" className="form-label">
                      Syllabus Pdf 
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      name="syllabuspdf"
                      onChange={handelOnChange}
                      className="form-control"
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Course Type</label> <br />
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="course_type"  onChange={handelOnChange} checked={formval?.course_type === "paid"} value="paid" id="paid" />
                      <label className="form-check-label"  htmlFor="paid">Paid</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio"  name="course_type" onChange={handelOnChange} checked={formval?.course_type === "unpaid"} value="unpaid" id="unpaid" />
                      <label className="form-check-label"    htmlFor="unpaid">Unpaid</label>
                    </div>
                  </div>
                </div>

                {formval?.syllabuspdf && <div className="mb-3"><iframe src={pdf || `${BASE_URL}/${formval?.syllabuspdf}`} onError={pdfError} width="180" height="100" id="pdfFrame"></iframe></div>}
                 {/* <div className="mb-3"><iframe src={pdf || `${BASE_URL}/${formval?.syllabuspdf}`} width="180" height="100" onLoad={pdfError} id="pdfFrame"></iframe></div> */}
                 {formval?.syllabuspdf ? null : (
                      <div id="errorMessage" style={{ color: 'red' }}>
                        PDF file is not available.
                      </div>
                      )}

                <div className="row align-items-center">
                  <div className="mb-3 col">
                    <label className="form-label">Select Subject</label>
                    <select
                      className="form-select"
                      name="subject"
                      onChange={handelOnChange} 
                      value={formval?.subject?._id}
                    >
                      <option>Select Subject</option>
                      {subject?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col">
                    <label className="form-label">Total Question</label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalQuestions"
                      onChange={handelOnChange}
                      value={formval?.totalQuestions}
                      min={0}
                    />
                  </div>
                  <div className="mb-3 col">
                    <label className="form-label">Total Marks</label>
                    <input
                      type="number"
                      className="form-control"
                      name="marks"
                      onChange={handelOnChange}
                      value={formval?.marks}
                      min={0}
                    />
                  </div>
                  <div className="mb-3 col">
                    <label className="form-label">Negative Mark</label>
                    <input
                      type="number"
                      className="form-control"
                      name="negMarks"
                      onChange={handelOnChange}
                      value={formval?.negMarks}
                      // min={0}
                    />
                  </div>
                  <div className="col ">
                    <label></label>
                    <button
                      type="button"
                      className="btn btn-primary me-3"
                      onClick={addRow}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                    &nbsp;
                     <Link
                        to={`/test_assign_duestion_add/${formval?._id}/${formval?.subject?._id}/${formval?.totalQuestions}`}
                        className="blu-btn">
                        Assign ({formval?.testassignQuestionCount || 0})
                  </Link>
                  </div>

                  {/* <div className="col">
                 
                  </div> */}

                </div>
                
                {row?.map((row, index) => (
                  <div className="row align-items-center" key={index}>
                    <div className="mb-3 col">
                      <label className="form-label">Select Subject</label>
                      <select
                        className="form-select"
                        name="subject"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row?.subject}
                      >
                        <option>Select Subject</option>
                        {subject?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Question</label>
                      <input
                        type="number"
                        className="form-control"
                        name="totalQuestions"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row?.totalQuestions}
                        min={0}
                      />
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Marks</label>
                      <input
                        type="number"
                        className="form-control"
                        name="marks"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row?.marks}
                        min={0}
                      />
                    </div>
              
                    <div className="mb-3 col">
                      <label className="form-label">Negative Mark</label>
                      <input
                        type="number"
                        className="form-control"
                        name="negMarks"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row?.negMarks}
                        // min={-1.0}
                      />
                    </div>
                    <div className="col">
                      <label></label>
                      <button
                        type="button"
                        className="btn btn-danger me-3"
                        onClick={row?._id?() => deletetestSubject(row?._id):() => removeRow(index)}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      &nbsp;
                    { row?.subject && <Link
                        to={`/test_assign_duestion_add/${formval?._id}/${row?.subject}/${row?.totalQuestions}`}
                        className="blu-btn">
                        Assign ({subjectCount[index] || 0})
                      </Link>}
                    </div>
                </div>
                ))}

                  <div className="mb-3 col-lg-12">
                    <React_Quill_Editor
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    />
                    {/* <Tinymce
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    /> */}
                  </div>
                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "./config/baseUrl";
import { toast, Bounce } from "react-toastify";

export default function Protected({ Component  }) {
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    // const role = localStorage.getItem("user");
    
    const checkAuth = async () => {
      if (!token) {
        localStorage.clear()
        navigate("/login");
        return;
      }
       
      // console.log(allowedRoles);

      try {
        
        // let response = await axios(
        //   `${BASE_URL}/api/user/authorize?permission=${allowedRoles}`,
        //   {
        //     method: "GET",
        //     headers: {
        //       Authorization: token,
        //     },
        //   }
        // );
        // console.log(response.data);

        // console.log(role)
        // console.log(allowedRoles)
        // console.log(!allowedRoles.includes(role))
        // if (!allowedRoles.includes(role)) {
        //   toast.error("You do not have access to this page", {
        //     position: "top-right",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     transition: Bounce,
        //   });
        //   // navigate("/");
        // }
        // if (!response.data.status) {
          // toast.error("You do not have access to this page", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   transition: Bounce,
          // });
          // alert("You do not have access to this page")
          // return navigate("/unauthorized_user")

        // }

      } catch (error) {
        console.log(error);
        toast.error("Please log in first", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        localStorage.clear();
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate]);

  return <Component />;
}

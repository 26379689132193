import React from 'react'
import Avtar from "../../assets/images/avtar.jpg"
import { Link } from 'react-router-dom'
export default function Header() {
    let userdata=JSON.parse(localStorage.getItem("user"))
    let toggleClass =()=>{
        // document.body.toggleClass("resize-menu");
        document?.body?.classList?.toggle("resize-menu");
    }
    let logout=()=>{
        localStorage.clear();
    }
    return (
        <>
            <header className="header">
                <div className="navbar-header">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-6">
                            <div className="header-fltr">
                                <button  className="toggle" onClick={toggleClass}>
                                    <i className="fas fa-times"  />
                                </button>
                                <form action="">
                                    <div className="header-fltr-bx">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type here..."
                                        />
                                        <span className="fas fa-search" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-6 col-6">
                            <div className="app-header-right-side">
                                <ul className="app-header-right-side-list">
                                    <li>
                                        <button type="button" className="position-relative">
                                            <i className="fa fa-bell" />
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                                                1
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                                <div className="prfl-bar">
                                    <div className="prfl-bar-img">
                                        <img src={Avtar} className="profle" alt="" />
                                    </div>
                                    <div className="prfl-bar-content">
                                        <div className="dropdown">
                                            <button
                                                className="dropdown-toggle"
                                                type="button"
                                                id="profile-menu"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {userdata?.firstName} <br/>
                                                {userdata?.user_type?.role || "NO ROlE"}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="profile-menu">
                                                {/* <li>
                                                    <a href="#">
                                                        <i className="far fa-user-circle me-2" />
                                                        Switch to user view
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fa fa-cog me-2" />
                                                        Setting
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-bell me-2" />
                                                        Notification
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fa fa-gift me-2 " />
                                                        Perk Marketplace
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <Link to="/login" onClick={logout} >
                                                        <i className="fa fa-sign-out-alt me-2" />
                                                        Signout
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

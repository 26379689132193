import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import Avtar from "../../assets/images/avtar.jpg";
import axios from "axios";
export default function User_details() {
  let { id } = useParams();
  let [formval, setFormval] = useState({});
  let [roleList, setRoleList] = useState([]);

  useEffect(() => {
    let apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/user/user_data/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setFormval({ ...response?.data?.data });
      // console.log(response.data.data)
    };
    apiCall();
    let roleList = async () => {
      let response = await axios(`${BASE_URL}/api/role/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response.data);
      setRoleList(response?.data?.data);
    };
    roleList();
  }, []);
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                User Details
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="">
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      disabled
                      defaultValue={formval?.firstName || formval?.name}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      disabled
                      defaultValue={formval?.lastName}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      DOB
                    </label>
                    <input
                      type="date"
                      disabled
                      defaultValue={formval?.dob?.split("T")[0]}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      disabled
                      defaultValue={formval?.email}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      disabled
                      defaultValue={formval?.phone}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Gender
                    </label>
                    <select
                      name=""
                      id=""
                      disabled
                      value={formval?.gender}
                      className="form-select"
                    >
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                      disabled
                      defaultValue={formval?.city}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      name=""
                      id=""
                      className="form-select"
                      value={formval?.status}
                      disabled
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input type="file" className="form-control" disabled />
                    <div className="image-box">
                      <div className="image-box-innr">
                        <img
                          src={`${BASE_URL}/${formval.profileImg}` || Avtar}
                          alt=""
                        />
                        {/* <a href="#">
                          <i className="far fa-times" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Assign Role
                    </label>
                    <select
                      value={formval?.user_type}
                      name="user_type"
                      className="form-select"
                      disabled
                    >
                      <option>Select Role</option>

                      {roleList?.map((arr) => (
                        <option value={arr?._id} key={arr?._id}>
                          {arr?.role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <Link to="/user_list" className="thm-btn rounded-2" type="button">
                      {"<"} Back
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

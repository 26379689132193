import React,{useState,useEffect} from 'react'
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import edit from "../../assets/images/edit.png";
import dummy from "../../assets/images/dummy.png";
import trash from "../../assets/images/trash.png";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";

function TestPackage_list() {
  let [data, setData] = useState([]);
  let [updateSequence, setUpdateSequence] = useState();
  let [searchPackageName ,setSearchPackageName]=useState("");
  let [pageNumber, setPageNumber] = useState(1)
  const [showPagination, setShowPagination] = useState(true);
  let [testPackageCount, setTestPackageCount] = useState([]);
  let [showIndex, setShowIndex] = useState(false);

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(`${BASE_URL}/api/testpackege/allcourses?p=${pageNumber}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response.data.data)
      // setData(response?.data?.data);
      if(!(response?.data?.status) && response.status === 200){
        setPageNumber(pageNumber-1)        
        return alert("Page Data Not Found ")
      }
      // console.log(response.data)
      setData(response?.data?.data?.allTestPackegeData);
      setTestPackageCount(response?.data?.data?.TestPackegeCount);
    };
    apicall();
  }, [pageNumber]);
  
  const handleUpdate = async (e) => {
    let response = await axios(
      `${BASE_URL}/api/testpackege/update_sequence/${e}?p=${pageNumber}`,
      {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: { sequence: updateSequence },
      }
    );
    // console.log(response.data.data);
    setData(response?.data?.data);
  };


  const handleStatus = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/testpackege/active/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response)
    };
    apiCall();
  };
  
  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/testpackege/delete/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let deletedUser = response?.data?.data;
      setData(
        data.filter((arr) => arr?._id !== deletedUser?._id)
      );
      setTestPackageCount((curr)=>curr-1)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Test Package has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const searchHandle = async (e) => { 
    e.preventDefault();
    setShowIndex(true)
    setShowPagination(false)
    let response = await axios(
      `${BASE_URL}/api/testpackege/search?package=${searchPackageName}`,
      // `${BASE_URL}/api/testcategory/search?category=${searchCategoryName}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response?.data?.status) {
      alert(response?.data?.message);
      return
    } else if(response?.data?.data?.length == 0){
        alert("Test Category Not Find")
    }else{
      setData(response?.data?.data);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Test Package List
              </h4>
              <Link className="blu-btn" to="/test_package_add">
                Add Test Package
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_package_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Test Package List 
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">Test Package List ({testPackageCount})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Package..."
                            onChange={(e)=>setSearchPackageName(e.target.value)}
                          />
                        </div> 
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      <th>Images</th>
                      <th>Sequence</th>
                      <th>price</th>
                      <th>Offer Price</th>
                      <th>Status</th>
                      <th>Test Category</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr,i)=>(
                        <tr key={arr?._id}>
                      <th>{showIndex ?++i:++i + 10 * (pageNumber - 1) }</th>
                        <td>{arr?.title}</td>
                        <td>
                          <img
                            src={`${BASE_URL}/${arr?.images[0]?.image}`}
                            className="img-40"
                            alt=""
                            onError={(e)=>e.target.src=dummy}
                          />
                          </td>
                        <td>
                          
                            <div className="d-flex table-box">
                              <input
                                type="number"
                                className="form-control"
                                defaultValue={arr?.sequence}
                                onChange={(e) =>
                                  setUpdateSequence(e.target.value)
                                }
                                min={0}
                              />
                              <button className="btn btn-success ms-2"  onClick={() => handleUpdate(arr._id)}>Update</button>
                            </div>
                          
                        </td>
                        <td>₹{arr?.price}</td>
                        <td>₹{arr?.offerPrice}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.isActive}
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>{arr?.testcategory?.title}</td>
                        <td>
                          <Link className="mx-1" to={`/testPackage_edit/${arr?._id}`}>
                            <img src={edit} alt="" />
                          </Link>
                          <Link className="mx-1"  onClick={() => {
                              handleDelete(arr?._id);
                            }}>
                            <img src={trash}  alt="" />
                          </Link>
                        </td>
                        {/* <td>
                          <Link className="blu-btn">Assign</Link>
                        </td> */}
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
                { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestPackage_list;

import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
function TestPackage_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    title: "",
    testcategory: "",
    images: [],
    description: "",
    price: "",
    offerPrice: "",
    course_validity:"",
    // isActive: true,
    sequence: "",
    thumbnail:""
  });
  let [categoryData, setCategoryData] = useState([]);
  const [image, setImages] = useState([]);
  let [img, setImg] = useState("");

  const handelOnChange = (e) => {
    if (e.target.name ==  "images") {
      let arr = [];
      let imagesArray = [];
      for (let i of e.target.files) {
        arr.push(i);
        const reader = new FileReader();
        reader.onload = (event) => {
          imagesArray.push(event.target.result);
          if (imagesArray.length === e.target.files.length) {
            setImages((prv) => {
              return [...prv, ...imagesArray];
            });
          }
        };
        reader.readAsDataURL(i);
      }
      setFormval((currVal) => ({
        ...currVal,
        images: [...formval.images, ...arr],
      }));
    } else if(e.target.name === 'thumbnail'){
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e?.target?.files[0]);
      setFormval((currVal) => ({ ...currVal, thumbnail: e.target.files[0] }));
    }else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/testcategory/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log(response.data.data);
      let filterData = response?.data?.data;
      filterData = response?.data?.data.filter((arr) => arr.isActive === true);
      setCategoryData(filterData);
    };
    apiCall();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    // console.log(formval)
    // return
    if (
      !formval.title ||
      !formval.testcategory ||
      !formval.price ||
      !formval.offerPrice ||
      !formval.images ||
      !formval.description||
      !formval.course_validity ||
      !formval.thumbnail ||
      !formval.sequence
    ) {
      alert("please fill all field");
      return;
    }
    const postData = new FormData();
    formval?.images?.map((arr) => {
      postData.append("images", arr);
    });
    postData.append("title", formval.title);
    postData.append("testcategory", formval.testcategory);
    postData.append("price", formval.price);
    postData.append("offerPrice", formval.offerPrice);
    postData.append("description", formval.description);
    postData.append("course_validity", formval.course_validity);
    postData.append("sequence", formval.sequence);
    postData.append("thumbnail", formval.thumbnail);

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/testpackege/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      // console.log(response?.data)
      if (!response?.data?.status) {
        alert("some error accoured");
        return;
      }
      navigate("/test_package_list");
    };
    apiCall();
  };
  
  let handleImgDelete = (e) => {
    setImages(image.filter((item, index) => index !== e));
    let array = formval.images.filter((item, index) => index !== e);
    setFormval((currVal) => ({ ...currVal, images: array }));
  };
  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Test Package
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Test Package
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Test Category</label>
                    <select
                      className="form-select"
                      name="testcategory"
                      onChange={handelOnChange}
                      defaultValue={formval?.testcategory}
                    >
                      <option>Select Test Category</option>
                      {categoryData.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>{arr?.title}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      onChange={handelOnChange}
                      value={formval.price}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Offer Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="offerPrice"
                      onChange={handelOnChange}
                      value={formval.offerPrice}
                      
                    />
                  </div>
                  
                   <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Sequence
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      onChange={handelOnChange}
                      value={formval.sequence}
                      className="form-control"
                      min={0}
                    />
                  </div>

                  <div className="mb-3 col-lg-4">
                    <label className="form-label">validity</label>
                    <input
                      type="date"
                      className="form-control"
                      name="course_validity"
                      onChange={handelOnChange}
                      value={formval.course_validity}
                      min={today}
                    />
                  </div>


                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="images"
                      onChange={handelOnChange}
                      //   value={formval.images}
                      multiple
                    />

                    <div className="image-box">
                      {image.map((imageUrl, index) => (
                        <div className="image-box-innr" key={index}>
                          <img src={imageUrl} alt={`Image ${index}`} />
                          <Link to="#" onClick={() => handleImgDelete(index)}>
                            <i className="fa fa-times" />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Thumbnail</label>
                    <input
                      type="file"
                      className="form-control"
                      name="thumbnail"
                      onChange={handelOnChange}
                    />
                    <div className="image-box">
                    <div className="image-box-innr">
                         {img && < img  src={img} alt={`thumbnail`} />}
                        </div>
                    </div>
                  </div>
      
                  
                  <div className="mb-3 col-lg-12">
                      <React_Quill_Editor
                        disabled={false}
                        data={formval?.description}
                        seteditor={setFormval}
                        fieldName={"description"}
                        editorTitle={"Description"}
                      />
                      {/* <Tinymce
                        disabled={false}
                        data={formval?.description}
                        seteditor={setFormval}
                        fieldName={"description"}
                        editorTitle={"Description"}
                      /> */}
                  </div>

                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestPackage_add;

import React, { useEffect, useState } from "react";
import axios from "axios";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import avtar from "../../assets/images/avtar.jpg";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
// import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import { Link, useNavigate } from "react-router-dom";
export default function User_list() {
  const navigate=useNavigate()
  let [userData, setUserData] = useState();
  let [pageNumber, setPageNumber] = useState(1);
  let [searchNumber, setSearchNumber] = useState(1);
  let [userCount, setUserCount] = useState(0);
  let [search, setSearch] = useState("");
  let [active, setActive] = useState(false);

  useEffect(() => {
    (async () => {
      let response = await axios(
        active
          ? `${BASE_URL}/api/user/search?search=${search}&p=${searchNumber}`
          : `${BASE_URL}/api/user/all_data?p=${pageNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data?.status)
      setUserCount(response?.data?.data?.userCount);
      setUserData(response?.data?.data?.data)
      if (!(response?.data?.status)) {
        alert(response.data.message);
        navigate("/")
        return
      } 
    })();
  }, [pageNumber, searchNumber]);


 let resetcallApi=async()=>{
  setPageNumber(1)
  setSearchNumber(1)
  setSearch("")
  setActive(false)
    let response = await axios(`${BASE_URL}/api/user/all_data?p=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    
    setUserCount(response?.data?.data?.userCount);
    setUserData(response?.data?.data?.data)
 }


  const handleStatus = (id) => {
    let apiCall = async () => {
      let response  = await axios(`${BASE_URL}/api/user/userstatus/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    };
    apiCall();
  };

  const searchHandle = async () => {
    if (search === "") {
      alert("empty search not allowed..!");
      return;
    }
    setSearchNumber(1);
    setActive(true);
    let response = await axios(
      `${BASE_URL}/api/user/search?search=${search}&p=${searchNumber}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response?.data?.status) {
      alert(response.data.message);
      return
    } else {
      setUserCount(response?.data?.data?.userCount);
      setUserData(response?.data?.data?.data);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Users List
              </h4>
              {/* <Link to="/user_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add User
              </Link> */}
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Users
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-6">
                    <h4 className="table-head">Total Users - {userCount}</h4>
                  </div>
                  <div className="col-lg-6">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                          />
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="blu-btn me-2"
                            onClick={searchHandle}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="blu-btn"
                            onClick={resetcallApi}
                          >
                            Reset Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>USER NAME</th>
                      <th>GENDER</th>
                      <th>DOB</th>
                      <th>EMAIL</th>
                      <th>Number</th>
                      <th>STATUS</th>
                      <th>created</th>
                      <th>PIN</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.map((arr, i) => {
                      return (
                        <tr key={arr._id}>
                          <th>
                            {active
                              ? ++i + 10 * (searchNumber - 1)
                              : ++i + 10 * (pageNumber - 1)}
                          </th>
                          <th>
                            <img
                              src={
                                arr?.profileImg
                                  ? `${BASE_URL}/${arr.profileImg}`
                                  : avtar
                              }
                              className="img-40"
                              alt=""
                            />
                          </th>
                          <td>{arr?.firstName || arr?.name}</td>
                          <td>{arr?.gender}</td>
                          <td>{arr?.dob?.split("T")[0]}</td>
                          <td>{arr?.email}</td>
                          <td>{arr?.phone}</td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={arr?.status}
                                onClick={() => {
                                  handleStatus(arr._id);
                                }}
                              />
                            </div>
                          </td>
                          <td>{arr?.createdAt?.split("T")[0] || arr?.created_datetime?.split(" ")[0]|| "NULL"}</td>
                          <td>{arr?.pin}</td>
                          <td>
                            <Link
                              to={`/user_details/${arr?._id}`}
                              className="mx-2"
                            >
                              <img src={view} alt="" />
                            </Link>
                            <Link to={`/User_edit/${arr._id}`} className="mx-2">
                              <img src={edit} alt="" />
                            </Link>
                            {/* <Link href="#" className="mx-2">
                              <img src={trash} alt="" />
                            </Link> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        disabled={
                          active
                            ? searchNumber <= 1 && true
                            : pageNumber == 1 && true
                        }
                        aria-label="Previous"
                        onClick={
                          active
                            ? () => setSearchNumber((prev) => prev - 1)
                            : () => setPageNumber((prev) => prev - 1)
                        }
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {!active && pageNumber > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link"
                          disabled={pageNumber == 1 && true}
                          aria-label="Previous"
                          onClick={
                            active
                              ? () => setSearchNumber((prev) => prev - 1)
                              : () => setPageNumber((prev) => prev - 1)
                          }
                        >
                          <span aria-hidden="true">
                            {active ? searchNumber - 1 : pageNumber - 1}
                          </span>
                        </button>
                      </li>
                    )}
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        {active ? searchNumber : pageNumber}
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link "
                        href="#"
                        onClick={
                          active
                            ? () => setSearchNumber((prev) => prev + 1)
                            : () => setPageNumber((prev) => prev + 1)
                        }
                      >
                        {active ? searchNumber + 1 : pageNumber + 1}
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={
                          active
                            ? () => setSearchNumber((prev) => prev + 2)
                            : () => setPageNumber((prev) => prev + 2)
                        }
                      >
                        {active ? searchNumber + 2 : pageNumber + 2}
                      </a>
                    </li>
                    <li
                      className="page-item"
                      onClick={
                        active
                          ? () => setSearchNumber((prev) => prev + 1)
                          : () => setPageNumber((prev) => prev + 1)
                      }
                    >
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

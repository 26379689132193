import React ,{useContext, useEffect,useState} from "react";
import axios from "axios";
import BASE_URL from "./Component/config/baseUrl";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/dashboard.css";
import "./assets/css/dashboard-responsive.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import { BrowserRouter, Routes, Route ,useNavigate } from "react-router-dom"

import Protected from "./Component/Protected";
import Dashboard from './Component/Home/Dashboard';
import Login from "./Component/Login/Login";
import User_list from "./Component/Users/User_list";
import User_add from "./Component/Users/User_add";
import User_edit from "./Component/Users/User_edit";
import User_details from "./Component/Users/User_details";

import VideoCourse_list from "./Component/Video_Courses/VideoCourse_list";
import VideoCourse_add from "./Component/Video_Courses/VideoCourse_add";
import VideoCourse_edit from "./Component/Video_Courses/VideoCourse_edit";
import VideoCourse_details from "./Component/Video_Courses/VideoCourse_details";

import VideoCourseCategory_add from "./Component/Video_Couse_Category/VideoCourseCategory_add";
import VideoCourseCategory_list from "./Component/Video_Couse_Category/VideoCourseCategory_list";
import VideoCourseCategory_edit from "./Component/Video_Couse_Category/VideoCourseCategory_edit";

import LiveVideoCourse_add from "./Component/Live_Video_Course/LiveVideoCourse_add";
import LiveVideoCourse_list from "./Component/Live_Video_Course/LiveVideoCourse_list";
import LiveVideoCourse_edit from "./Component/Live_Video_Course/LiveVideoCourse_edit";
import LiveVideoCourse_details from "./Component/Live_Video_Course/LiveVideoCourse_details";

// import VideoCourseCategory_list from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_list";
import LiveVideoCourseCategory_list from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_list";
import LiveVideoCourseCategory_add  from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_add"
import LiveVideoCourseCategory_edit from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_edit";

import Role_list from "./Component/Role/Role_list";
import NewRole from "./Component/Role/NewRole";
import Role_edit from "./Component/Role/Role_edit";
import Role_view from "./Component/Role/Role_view"

import Permission_list from "./Component/Permission/PermissionList";

import Test_list from "./Component/Test/Test_list";
import Test_add from "./Component/Test/Test_add";
import Test_edit from "./Component/Test/Test_edit"

import Test_Category_list from "./Component/Test_Category/Test_Category_list"
import Test_Category_add from "./Component/Test_Category/Test_Category_add"
import Test_Category_edit from "./Component/Test_Category/Test_Category_edit"

import TestPackage_list from "./Component/Test_Package/TestPackage_list";
import TestPackage_add from "./Component/Test_Package/TestPackage_add";
import TestPackage_edit from "./Component/Test_Package/TestPackage_Edit"

import Question_List from "./Component/Question_Bank/Question_List";
import Question_Add from "./Component/Question_Bank/Question_Add";
import Question_edit from "./Component/Question_Bank/Question_edit";
import Question_view from "./Component/Question_Bank/Question_view";
import Test_Assign_Question_add from "./Component/TestAssignQuestion/Test_Assign_Question_add";

import Subject_list from "./Component/Subject/Subject_list"
import Subject_add from "./Component/Subject/Subject_add";
import Subject_edit from "./Component/Subject/Subject_edit"

import Ras_Package_list from "./Component/RasPackage/Ras_Package_list"
import Ras_Package_Add from "./Component/RasPackage/Ras_Package_add"
import Ras_Package_Edit from "./Component/RasPackage/Ras_Package_Edit"
import Ras_Package_Assign_Test_List from "./Component/RasPackage/Ras_Package_Assign_Test_List"

import Ras_Package_Test_list from "./Component/Ras_Package_Test_List/Ras_Package_Test_List"
import Ras_Package_Test_Add from "./Component/Ras_Package_Test_List/Ras_Package_Test_Add"
import Ras_Package_Test_Edit from "./Component/Ras_Package_Test_List/Ras_Package_Test_Edit"
import Layout from "./Layout";

import FreeVideoPdf_List from "./Component/FreeVideoPdf/FreeVideoPdf_List";
import FreeVideoPdf_Add from "./Component/FreeVideoPdf/FreeVideoPdf_Add";
// /add test list in assign test
import FreeVideoPdfSubject_add from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_add";
import FreeVideoPdfSubject_edit from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_edit"
import FreeVideoPdfSubject_list from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_list"
import FreeVideoPdf_Edit from "./Component/FreeVideoPdf/FreeVideoPdf_Edit";
import Banner_list from "./Component/Banner/Banner_list";
import Banner_add from "./Component/Banner/Banner_add";
import Banner_edit from "./Component/Banner/Banner_edit";
import Unauthorized from "./Component/config/Unauthorized";
import RouteNotFind from "./Component/config/RouteNotFind";
 
 
import RolePermissionContext from "./contextApi/rolePermissionContext";
 
function App() {
  const  user = JSON.parse(localStorage?.getItem("user"))
 
  
  const { userPermission,setUserPermission} = useContext(RolePermissionContext);
  // console.log(userPermission,"thisis dara")
  // let  [userPermission,setUserPermission]=useState()
  // let []=useState()
 let fetchData=async (id) => {
  console.log(id,"this is id s");
  console.log(user?.user_type?._id,"this is id");
  if( !id ){
    return
  }
  let response = await axios(
    `${BASE_URL}/api/rolepermission/show/${user?.user_type?._id || id}`,
    {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }
  );
  console.log(response?.data );
  
  let filter= response?.data?.data?.permissionCategoryId?.map((arr)=>(arr.category))
  setUserPermission(filter)
} 

let apiCall = async () => {
  let response = await axios(`${BASE_URL}/api/user/user_data/${user?._id}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
  console.log(response?.data);
  console.log(response?.data.data?.user_type,"this is id  ");

  fetchData(response?.data?.data?.user_type);
   
   
};
  useEffect(()=>{
    apiCall()
  },[])
 

  return (
    <>
      <BrowserRouter>
      <Routes>
          <Route path="/login" element={< Login  onSuccess={fetchData} />} />
          <Route path="/" element={<Layout /> }>
          <Route index element={<Protected Component={Dashboard}  allowedRoles={'home'}/>} />
          {userPermission?.includes("user Mangement") &&(<>
          <Route path="/user_list" element={<Protected Component={User_list} allowedRoles={'read user'} />} />
          <Route path="/user_add" element={< Protected Component={User_add} allowedRoles={'add user'} />} />
          <Route path="/User_edit/:id" element={< Protected Component={User_edit} allowedRoles={'edit user'}  />} />
          <Route path="/user_details/:id" element={< Protected Component={User_details} allowedRoles={'read read'} />} /></>)}

          {userPermission?.includes("video Mangement") && (<>
            <Route path="/video_course_list" element={<Protected Component={VideoCourse_list} allowedRoles={'read video'}/>} />
            <Route path="/video_course_add" element={<Protected Component={VideoCourse_add} allowedRoles={'add video'}/>} />
            <Route path="/video_course_edit/:id" element={<Protected Component={VideoCourse_edit} allowedRoles={'edit video'}/>} />
            <Route path="/video_course_details/:id" element={<Protected Component={VideoCourse_details} allowedRoles={'read video'} />} />

            <Route path="/video_course_category_list" element={< Protected Component={VideoCourseCategory_list} />} />
            <Route path="/video_course_category_add" element={< Protected Component={VideoCourseCategory_add} />} />
            <Route path="/VideoCourseCategory_edit/:id" element={< Protected Component={VideoCourseCategory_edit} />} />
          </>)}


          {userPermission?.includes("live video Mangement") && (<>
            <Route path="/live_video_course_list" element={<Protected Component={LiveVideoCourse_list} />} />
            <Route path="/live_video_course_add" element={<Protected Component={LiveVideoCourse_add} />} />
            <Route path="/live_video_course_edit/:id" element={<Protected Component={LiveVideoCourse_edit} />} />
            <Route path="/live_video_course_details/:id" element={< Protected Component={LiveVideoCourse_details} />} />

            <Route path="/live_video_course_category_list" element={<Protected Component={LiveVideoCourseCategory_list} />} />
            <Route path="/live_video_course_category_add" element={<Protected Component={LiveVideoCourseCategory_add} />} />
            <Route path="/live_video_course_category_edit/:id" element={<Protected Component={LiveVideoCourseCategory_edit} />} />
          </>)}

          {userPermission?.includes("role Mangement") && (<> 
          <Route path="/role_list" element={<Protected Component={Role_list} allowedRoles={'read role'} />} />
          <Route path="/role_add" element={<Protected Component={NewRole} allowedRoles={'add role'} />} />
          <Route path="/role_edit/:id" element={<Protected Component={Role_edit} allowedRoles={'delete role'}  onSuccess={fetchData} />} />
          <Route path="/role_view/:id" element={<Protected Component={Role_view} allowedRoles={'edit role'}/>} />

          <Route path="/permission_list" element={<Protected Component={Permission_list} allowedRoles={'allowed'} />} />
          </>)  }

          {userPermission?.includes("test Mangement") && (<> 
          <Route path="/test_list" element={<Protected Component={Test_list}  allowedRoles={'read test'}/>} />
          <Route path="/test_add" element={<Protected Component={Test_add} allowedRoles={'add test'}/>} />
          <Route path="/test-edit/:id" element={<Protected Component={Test_edit} allowedRoles={'edit test'}/>} />

          <Route path="/test_category_list" element={<Protected Component={Test_Category_list} allowedRoles={'read test category'} />} />
          <Route path="/test_category_add" element={<Protected Component={Test_Category_add} allowedRoles={'add test category'} />} />
          <Route path="/test_category_edit/:id" element={<Protected Component={Test_Category_edit} allowedRoles={'edit test category'} />} />

          <Route path="/test_package_list" element={<Protected Component={TestPackage_list} />} />
          <Route path="/test_package_add" element={<Protected Component={TestPackage_add} />} />
          <Route path="/testPackage_edit/:id" element={<Protected Component={TestPackage_edit} />} />
          
          <Route path="/question_list" element={<Protected Component={Question_List} />} />
          <Route path="/question_add" element={<Protected Component={Question_Add} />} /> 
          <Route path="/question_edit/:id" element={<Protected Component={Question_edit} />} /> 
          <Route path="/question_view/:id" element={<Protected Component={Question_view} />} />

          <Route path="/subject_list" element={<Protected Component={Subject_list} />} /> 
          <Route path="/subject_add" element={<Protected Component={Subject_add} />} /> 
          <Route path="/subject_edit/:id" element={<Protected Component={Subject_edit} />} /> 

          <Route path="/test_assign_duestion_add/:testid/:subjectid/:totalQuestion" element={<Protected Component={Test_Assign_Question_add} />} />
          </>)  }
          {/* Ras_Package_list  */}

          {userPermission?.includes("ras package Mangement") && (<> 
          <Route path="/ras_package_list" element={<Protected Component={Ras_Package_list} />} /> 
          <Route path="/ras_package_add" element={<Protected Component={Ras_Package_Add} />} /> 
          <Route path="/ras_package_edit/:id" element={<Protected Component={Ras_Package_Edit} />} /> 
          <Route path="/ras_package_assign_test_list/:id" element={<Protected Component={Ras_Package_Assign_Test_List} />} /> 

          <Route path="/ras_package_test_list" element={<Protected Component={Ras_Package_Test_list} />} /> 
          <Route path="/ras_package_test_add" element={<Protected Component={Ras_Package_Test_Add} />} /> 
          <Route path="/ras_package_test_edit/:id" element={<Protected Component={Ras_Package_Test_Edit} />} />
          </>)  }

          {userPermission?.includes("free video/Pdf Mangement") && (<> 
          <Route path="/free_video_pdf_list" element={<Protected Component={FreeVideoPdf_List} />} />  
          <Route path="/free_video_pdf_Add" element={<Protected Component={FreeVideoPdf_Add} />} />  
          <Route path="/free_video_pdf_edit/:id" element={<Protected Component={FreeVideoPdf_Edit} />} />  

          <Route path="/free_video_pdf_subject_add" element={<Protected Component={FreeVideoPdfSubject_add} />} />  
          <Route path="/free_video_pdf_subject_edit/:id" element={<Protected Component={FreeVideoPdfSubject_edit} />} />  
          <Route path="/free_video_pdf_subject_list" element={<Protected Component={FreeVideoPdfSubject_list} />} />  
          </>)  }

          {userPermission?.includes("banner Mangement") && (<> 
          <Route path="/banner_list" element={<Protected Component={Banner_list} />} />  
          <Route path="/banner_add" element={<Protected Component={Banner_add} />} />  
          <Route path="/banner_edit/:id" element={<Protected Component={Banner_edit} />} />  
          <Route path="/Unauthorized_user" element={<Protected Component={Unauthorized} />} />  
          </>)  }
        </Route>
          <Route path="*" element={<Protected Component={RouteNotFind} />} />  
      </Routes>
      </BrowserRouter>
   
    </>
  );
}


export default App;
// defaultChecked={(cell?.category?._id==rolePermission?.permissionId[i]?.category )?true:false}
import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import BASE_URL from "../config/baseUrl";
// import Ras from "../../assets/images/ras.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function FreeVideoPdf_List() {
  let [freeVideoPdfData, setFreeVideoPdfData] = useState([]);
  let [updateSequence, setUpdateSequence] = useState();
  let [searchTitle, setSearchTitle] = useState("");
  let [searchtype, setSearchtype] = useState("");
  const [pageNumber, setPageNumber] = useState(1)
  const [showPagination, setShowPagination] = useState(true);
  let [datacount, setDatacount] = useState(0);
  let [showIndex, setShowIndex] = useState(false);


  useEffect(() => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/freeVideoPdf/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data) 
      setFreeVideoPdfData(response?.data?.data || []);
    };
    apiCall();
  }, []);

  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios( //C:\Users\admin\Downloads\qed_reactj_gitlab\src\Component\FreeVideoPdf\FreeVideoPdf_List.js
        `${BASE_URL}/api/freevideopdf/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let deletedUser = response.data.data
      setFreeVideoPdfData(freeVideoPdfData?.filter((arr) => arr?._id !== deletedUser?._id));
      setDatacount((curr) => curr - 1)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "video course category has been deleted.",
          icon: "success",
        });
      }
    });
  };

  
  const searchHandle = async (e) => {
    e.preventDefault();
    // if (search === "") {
    //   alert("empty search not allowed..!");
    //   return;
    // }
    // setSearchNumber(1);
    // setActive(true);
    let response = await axios(
      `${BASE_URL}/api/freeVideoPdf/search?search=${searchTitle}&type=${searchtype} `,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    console.log(response.data)
    if (!response?.data?.status) {
      alert(response.data.message);
      return
    } else {
      // setUserCount(response?.data?.data?.userCount);
      // setUserData(response?.data?.data?.data);
      setFreeVideoPdfData(response?.data?.data)
    }
  };
  return (
    < >
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Free Video Pdf
              </h4>
              <Link to="/free_video_pdf_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Free Video/Pdf
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/live_video_course_list">
                      Free Video/Pdf
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Free Video/Pdf List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Free Video/Pdf ({freeVideoPdfData.length})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle} >
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-4 ">
                            <select
                            name="type"
                            // onChange={handelOnChange}
                            onChange={(e) => setSearchtype(e.target.value)}
                            className="form-select"
                          >
                            <option value={""}>Select Type</option>
                            <option value={"pdf"} >Pdf</option>
                            <option value={"video"}>Video</option>
                          </select>
                        </div>
                        {/* <div className="col-lg">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search title..."
                            onChange={(e) => setSearchTitle(e.target.value)}
                            value={searchTitle}
                          />
                        </div> */}
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    {/* title: title,
        type: type,
        photo : filename,
        image : filename,
        subject: subject, */}
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      <th>Type</th>
                      {/* <th>Photo</th> */}
                      <th>Image</th>
                      <th>Subject</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {freeVideoPdfData?.map((arr, i) => (
                      <tr key={arr._id}>
                        <th>{showIndex ? ++i : ++i + 10 * (pageNumber - 1)}</th>
                        <th>{arr.title}</th>
                        <th>
                          {/* <img
                            src={`${BASE_URL}/${arr.}`}
                            className="img-40"
                            alt=""
                          /> */}
                          {arr.type}
                        </th>
                        <td>
                          <img
                            src={`${BASE_URL}/${arr.image}`}
                            className="img-40"
                            alt=""
                          />

                          {/* {`${BASE_URL}/${arr.image}`} */}
                        </td>
                        <td>{arr?.subject?.title}</td>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              // defaultValue={arr?.categorySequence}
                              // onChange={(e) =>
                              //   setUpdateSequence(e.target.value)
                              // }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              // onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td> */}
                        {/* <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // defaultChecked={arr?.isActive}
                              // onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td> */}
                        <td>
                          <Link
                            to={`/free_video_pdf_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            onClick={() => handleDelete(arr._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
